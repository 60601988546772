@charset "UTF-8";

// 7-1 architecture
// https://www.learnhowtoprogram.com/user-interfaces/building-layouts-preprocessors/7-1-sass-architecture

// 1. abstracts
@import 'abstract/variables',
  'abstract/mixins';

// 2. vendors
@import
'~bootstrap/scss/bootstrap',
'vendor/fontawesome/fontawesome.scss',
'vendor/fontawesome/regular.scss',
'vendor/fontawesome/solid.scss',
'vendor/fontawesome/brands.scss';
//   '~primevue/resources/themes/bootstrap4-light-blue/theme.css',
// '~primevue/resources/primevue.min.css',
// '~primeicons/primeicons.css';



// 3. base
@import 'base/reset',
 'base/typography',
'base/font',
'base/essentials';

// 4. layout
@import 'layout/header',
  'layout/navigation',
  'layout/layout';

// 5. components
@import 'component/p-button',
'component/p-panelmenu',
'component/p-datatable',
'component/p-tabs',
'component/p-input',
'component/p-upload',
'component/p-checkbox',
'component/p-radio',
'component/p-dialog',
'component/loader',
'component/p-tooltip',
'component/p-multiselect',
'component/p-dropdown',
'component/p-breadcrumb',
'component/p-listbox',
'component/p-accordion',
'component/p-datepicker',
'component/p-editor',
'component/input-switch',
'component/picklist',
'component/p-datascroller';

// 6. pages
@import 'page/login',
'page/import-file-exceptation',
'page/suppression-file-exceptation',
'page/error',
'page/query-detail',
'page/new-query',
'page/user-management',
'page/cohortupload',
'page/table-management',
'page/role-permissions',
'page/data-search',
'page/sign-up',
'page/confirmation-page',
'page/myprofile',
'page/upload-data';

// 7. themes




