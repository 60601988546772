.p-accordion{
 .p-accordion-header {
  .p-accordion-header-link {
   background: transparent;
   border:2px solid $mdgprimary;
   border-width:0 0 2px 0;
   .p-accordion-toggle-icon{
    position: absolute;
    right: 0;
    font-size: 1.125rem;
    color: $mdg-title;
    font-weight: 900;
    &.pi-chevron-down:before {
     content: "\f107";
     font-family: "Font Awesome 5 Free";
     font-weight: 900;
    }
    &.pi-chevron-right:before {
     content: "\f105";
     font-family: "Font Awesome 5 Free";
     font-weight: 900;
    }
   }
  }
  &:not(.p-disabled) .p-accordion-header-link:focus{
   box-shadow: none;
  }
  &:not(.p-disabled).p-highlight:hover {
   .p-accordion-header-link{
    background: transparent;
    border-color: $mdgprimary;
   }
  }
  &:not(.p-disabled).p-highlight {
   .p-accordion-header-link{
    border-color: $mdgprimary;
   }
  }
  &:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link{
   background: transparent;
   border-color: $mdgprimary;
  }
 }
 .p-accordion-content{
  padding: 0;
  background: transparent;
  border: 0;
 }
 .p-accordion-tab{
  .p-accordion-header {
   .p-accordion-header-link{
    padding-left: 0;
   }
  }
  &:last-child .p-accordion-header:not(.p-highlight) {
   .p-accordion-header-link{
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
   }
  }
 }
}

.p-accordion-header-text{
 font-size: 1.125rem;
 font-weight: 900;
 color: $mdg-title
}
