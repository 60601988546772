
.p-dialog-header-close-icon{
  color: $white !important;
 }

.p-dialog {
 .p-dialog-header{
  background: $mdgprimary;
  padding: .625rem 1.5rem;
  color: $white;
  .p-dialog-title{
   font-weight: 400;
   font-size: 1.125rem;
  }
  .p-dialog-header-icon:enabled:hover{
   .p-dialog-header-close-icon{
     color: $mdgprimary !important;
   }
  }
 }
}

.confitm-delete-dialog{
  .p-dialog-content{
    padding-right: 0;
    overflow: hidden;
  }
  ul{
    margin-top: .9375rem;
    padding-left: 0;
    li{
      margin-bottom: .3125rem;
    }
  }
  label{
    font-size: 1rem;
  }
  a{
    font-size: 1rem;
  }
  .delete-list{
    max-height: 150px;
    overflow: auto;
  }
}