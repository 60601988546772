.p-inputtext {
  font-size: .875rem;
  background: initial;
  padding-left: 0;
  &:not(.p-dropdown-label) {
    // padding-bottom: 0;
  }
  &:enabled:focus{
    box-shadow: none;
    border-color: initial;
  }
  border: 1px dotted $gray-light;
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.form-control{
  &:focus{
    background-color: transparent;
  }
}

.form-control{
  &:focus{
    color: inherit;
  }
}
