.download-dropdown{
  box-shadow: 4px 4px 4px #a5a5a5;
  padding: 0;
  li{
   padding: .1875rem 0;
   a{
    font-size: .875rem;
   }
  }
}

.section-divide-OR{
    text-align: center;
    background-color: #f4f4f4;
    color: #272b35;
    font-size: 18px;
    padding: 6px;
    margin: 10px 0;
}