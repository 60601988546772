.required-field{
 font-size: 10px;
}

.white-box-title{
 font-size: 1.125rem;
 font-weight: bolder;
}

.role-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}
.role-badge.status-Inactive {
    background: #ffcdd2;
    color: #c63737 !important;
}
.role-badge.status-Pinding {
    background: #feedaf;
    color: #8a5340 !important;
}
.role-badge.status-Active {
    background: #c8e6c9;
    color: #256029 !important;
}

.p-dialog .p-dialog-content{
    padding-top: 15px !important;
}