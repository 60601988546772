.app-header-wrapper {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .nav-left{
    display: none;
    @include mq(tablet) {
      display: block;
    }
  }
}

.mdg-layout-header {
  height: 50px;
  line-height: 50px;
  padding: 0;
  background-color: #fff;
  display: flex;
  width: 100%;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.15);
  .feather-sun{
    display: none;
  }
  &.darktheme{
    .feather-sun{
      display: block;
    }
    .feather-moon{
      display: none;
    }
  }
  @include mq(sm) {
    flex-direction: column;
    height: auto;
  }
  .mobile-logo-title{
    display: none;
    @include mq(sm) {
      display: block;
    }
  }
}

.app-header {
    position: fixed;
    left: 0;
    z-index: 1040;
    top: 0;
    // box-shadow: 0 1px 4px -1px rgb(0 0 0 / 15%);
}

.logo {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 1rem;
  // background-color: mdgprimary;
  transition: all 0.2s ease;
  width: 230px;
  justify-content: center;
  
  @include mq(tablet) {
   width: auto;
  }
  @include mq(sm) {
    width: 80px !important;
    margin-left: 20px;
   }
  img{
    @include mq(md) {
      height: 22px;
    }
  }
  h1{
      margin: 0;
      color: $white;
      font-weight: 900;
  }
  .header-menuicon{
    font-size: 1.6875rem;
    cursor: pointer;
    @include mq(md) {
      font-size: 1.125rem;
    }
  }
}

.nav {
  // width: calc(100% - 270px);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  position: relative;
  transition: all 0.2s ease;
  background: mdgprimary;
  i{
      font-size: 1.125rem;
  }
  
  .nav-right{
    padding: 0 1rem;
    ul{
      @include mq(xxs) {
        padding-left: 0;
      }
        .nav-right-item{
            padding: 0 1rem;
            margin: 0;
            display: inline-block;
            vertical-align: middle;
            img{
              @include mq(md) {
                display: none;
              }
            }
        }
    }
  }
}

.nav-left{
  font-size: 1.25rem;
  text-transform: uppercase;
  display: inline-block;
  position: absolute;
  right: -30px;
  top: 7px;
  // transform: translateY(-50%);
  @include mq(tablet) {
    position: relative;
    right: initial;
    top: initial;
    transform: initial;
    display: none;
  }
  .header-left-title{
    padding-right: .625rem;
    color: $white !important;
    cursor: pointer;
    font-weight: bold;
    &.header-menuicon{
      i{
        height: 24px;
        width: 24px;
        border-radius: 100%;
        display: inline-block;
        line-height: 24px;
        text-align: center;
        margin-right: 10px;
        font-weight: bold;
        color: $white;
        font-size: 12px;
        @include mq(xxs) {
          height: 20px;
          width: 20px;
          line-height: 20px;
        }
      }
    }
    img{
      @include mq(md) {
        height: 22px;
      }
    }
  }
  .header-right-title{
    padding-left: .625rem;
    // color: $white !important;
    font-weight: bold;
  }
}

.app-heading{
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: bold;
    padding-left: 1.25rem;
}

.sidebarclose{
  .nav-left {
    .header-left-title{
      &.header-menuicon {
        .fa-chevron-left:before {
          content: "\f054";
        }
      }
    }
  }
  .logo{
    width: 165px;
    @include mq(tablet) {
      width: auto;
    }
  }
}

.user-name{
  font-size: 14px;
  @include mq(xs) {
    display: none;
  }
}

.sidebaropen{
  .nav{
    width: calc(100% - 250px);
    @include mq(md) {
      width: auto;
    }
  }
}

.sidebarclose{
  .nav{
    width: calc(100% - 185px);
    @include mq(md) {
      width: auto;
    }
  }
}

.switch-platform-logo{
  @include mq(md) {
    display: none;
  }
}