.p-field-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    label{
        margin-left: .5rem;
        line-height: 1;
    }
}

.p-checkbox {
    align-items: center;
    width:16px;
    height:16px;
    &:not(.p-checkbox-disabled) {
        .p-checkbox-box{
            &.p-focus{
                box-shadow: none;
                border-color: #ced4da;
            }
        }
    }
    .p-checkbox-box{
        width:16px;
        height:16px;
        .p-checkbox-icon{
            font-size: 12px;
        }
    }
}



