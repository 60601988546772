body{
    background-color:$body-bg;
    font-family: $font-family-sans-serif;
    // transition: all .4s ease-in-out;
}

.mdg-layout{
 padding-left: 230px;
//  transition: all .3s ease;
 @include mq(tablet) {
    padding-left: 0;
  }
}

.app-content{
    margin-top: 53px;
    min-height: calc(100vh - 50px);
    position: relative;
    .app-content-inner{
      padding: 1.25rem;
    }
}

.layout-expand{
 .mdg-layout{
  padding-left: 88px;
  @include mq(tablet) {
    padding-left: 0;
  }
 }
 .nav-left{
  @include mq(tablet) {
    display: none;
  }
 }
}

.page-title{
  display: flex;
  align-items: center;
  @include mq(xs) {
    align-items: flex-start;
  }
  .header-button{
    @include mq(sm) {
      text-align: right;
      margin-top: .625rem;
    }
  }
  .page-title-icon{
    font-size: 1.25rem;
    margin-right: .9375rem;
  }
  h1{
    font-size: 1.5rem;
    font-weight: 900;
    color: $mdg-title;
    margin-bottom: 0;
  }
}

.text-red{
  color:red !important;
}