.p-dropdown{
 border: 1px dotted $gray-dark;
 border-width: 0 0 1px 0;
 // border-radius: 0;
 background: transparent;
 .p-dropdown-trigger{
  justify-content: right;
  .p-dropdown-trigger-icon{
   font-size: .75rem;
   padding-top: .25rem;
  }
 }
 &:not(.p-disabled).p-focus{
  box-shadow: none;
 }
}

.dropdown_link{
 &.p-dropdown{
  border: 0;
  color: #0093e7;
  .p-dropdown-trigger{
   display: none;
  }
 }
}

.p-datatable {
 .p-datatable-tbody {
  tr{
   td{
    &.dropdown_like_link{
     color: #0093e7 !important;
     cursor: pointer;
    }
   }
  }
 }
}

.p-dropdown-panel {
 .p-dropdown-items{
  .p-dropdown-item{
   padding: .125rem 1rem;
  }
 }
}

.tabletoimportdropdown{
 .p-dropdown-panel {
  z-index: 1040 !important;
 }
}

.dropdown-primary{
 &.p-dropdown {
  border-width: 0;
  height: 2.125rem;
  line-height: 2.125rem;
  .p-dropdown-trigger{
   padding-right: 5px;
  }
  .p-dropdown-label{
   padding-left: 5px;
   text-transform: uppercase;
   color: $white !important;
   line-height: initial;
  }
  .p-dropdown-clear-icon{
   color: $white;
   margin-top: -4px;
   font-size: 10px;
  }
  .p-dropdown-trigger {
   .p-dropdown-trigger-icon{
    color: $white !important;
   }
  }
 }
 .p-dropdown-panel{
  .p-dropdown-header{
   .p-inputtext {
    color: #666666;
   }
  }
 }
}

