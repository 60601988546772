.p-datepicker-buttonbar{
 .p-button {
  &.btn-primary{
    color: #fff;
    background-color: #0093e7;
    border-color: #0093e7;
  }
  &.btn-secondary{
    color: #000;
    background-color: #e1e1e1;
    border-color: #e1e1e1;
    .p-button-label{
     color: initial !important;
    }
   }
 }
}

.p-datepicker {
  z-index:1050 !important;
 table {
  th{
    font-size: 14px;
    text-align: center;
  }
  td {
    font-size: 13px;
   span{
    width: 2rem;
    height: 2rem;
   }
  }
 }
}

