.dropdown-menu {
    .dropdown-item {
        line-height: initial;
    }
}

.help-block {
    font-size: 12px;
}

/*------width and height-----------*/
$max-width: 1500;

@for $i from 1 through $max-width {
    $value: $i * 1;
    .width#{$value} { width: $i+px;}
} 

$max-height: 350;

@for $i from 1 through $max-height {
    $value: $i * 1;
    .height#{$value} { height: $i+px;}
} 

// font size
.font-15{
    font-size: .9375rem;
}

// font weight
.weight-300 { font-weight:300 !important;}
.weight-400 { font-weight:400 !important;}
.weight-500 { font-weight:500 !important;}
.weight-700 { font-weight:700 !important;}
.weight-900 { font-weight:900 !important;}

.filter-color{
    color: $ascent3;
}

.columns-color{
    color: $ascent4;
}

.column-color{
    color: $ascent5;
}

.change-password{
    .p-inputtext{
        width: 100%;
    }
    label{
        font-size: 1rem;
    }
}

label{
    position: relative;
}

.fa-asterisk{
    position: absolute;
    font-size: 6px;
    top: 2px;
    color: #d00000;
    padding-left: 4px;
}

.icon_space {
    margin-right: 8px;
}