.p-field-radiobutton {
 label{
  margin-left: 0;
  line-height: 1;
 }
}

.p-radiobutton {
 vertical-align: initial;
 .p-radiobutton-box{
  width: 14px;
  height: 14px;
  .p-radiobutton-icon{
   width: 8px;
   height: 8px;
  }
  &:not(.p-disabled).p-focus{
   box-shadow: none;
  }
 }
}

.mdg-bs-radio label > input[type="radio"] {
 display: none;
}
.mdg-bs-radio label > input[type="radio"] + *::before {
 content: "";
 display: inline-block;
 vertical-align: text-top;
 width: 1rem;
 height: 1rem;
 margin-right: 0.3rem;
 border-radius: 50%;
 border-style: solid;
 border-width: 2px;
 border-color: #ced4da;
}
.mdg-bs-radio label > input[type="radio"]:checked + * {
 color: $mdgprimary;
}

.mdg-bs-radio label > input[type="radio"] + * {
 display: inline-block;
}