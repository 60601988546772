body {
 &.signup-page {
     background: rgba(0, 0, 0, 0) url(../../img/login-bg2.jpg) repeat scroll 0 0;
     background-size: cover;
     @include mq(md) {
      padding: 50px;
   }
 }
}

.signup-main{
  background: #fff none repeat scroll 0 0;
  width: 500px;
  margin: 50px auto;
  @include mq(md) {
    width: 100%;
 }
 .p-float-label input:focus ~ label, 
 .p-float-label input.p-filled ~ label, 
 .p-float-label textarea:focus ~ label, 
 .p-float-label textarea.p-filled ~ label, 
 .p-float-label .p-inputwrapper-focus ~ label, 
 .p-float-label .p-inputwrapper-filled ~ label{
  top: 0;
}
.p-float-label > label{
 left: 0;
}
}