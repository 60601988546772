.p-inputswitch {
 width: 2.25rem;
 height: 1.25rem;
 .p-inputswitch-slider:before{
  width: 1rem;
  height: 1rem;
  left: 3px;
  margin-top: -0.5rem;
 }
 &.p-inputswitch-checked{
   .p-inputswitch-slider:before{
     width: 1rem;
     height: 1rem;
     left: -3px;
     margin-top: -0.4875rem;
   }
  }
}

