.p-multiselect{
 padding-right: 0;
 background: initial;
 width: 100%;
 &:not(.p-disabled).p-focus{
  box-shadow: none;
  border-color: initial;
 }
 border: 1px dotted $gray-light;
 border-width: 0 0 1px 0;
 border-radius: 0;
 .p-multiselect-trigger{
  justify-content: right;
 }
 .p-multiselect-label{
  padding-left: 0;
  &.p-placeholder{
   font-size: .875rem;
   padding-left: 0;
   // color:#666666;
  }
 }
}

.p-multiselect {
    .p-multiselect-clear-icon{
        right: 1.5rem;
    }
}

.p-multiselect-panel {
    .p-multiselect-items {
        .p-multiselect-item{
            font-size: 13px;
        }
    }
}



