html,
body {
    font-size: 16px; 
    color: $secondary;

    @include mq(xxl) {
        font-size: 14px;
    }

    @include mq(xs) {
        font-size: 12px;
    }
}


