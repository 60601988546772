.side-nav {
  .p-panelmenu {
    .p-panelmenu-content {
      border: 0;
      padding: 0;
      background: initial;

      .p-menuitem {
        .p-menuitem-link {
          &:not(.p-disabled):hover {
            background: transparent;

            .p-menuitem-text {
              color: $primary;
            }
          }
        }

        .p-menuitem-link {
          &:focus {
            box-shadow: none;

            .p-menuitem-text {
              color: $primary;
            }
          }

          &:hover {
            color: $primary;
          }
        }
      }
    }

    .p-panelmenu-header {
      a {
        font-weight: 600;
      }

      &:not(.p-disabled) {
        a {
          &:hover {
            background: transparent;
            border-color: transparent;
            color: $primary;
          }
        }
      }

      &:not(.p-highlight):not(.p-disabled) {
        a {
          position: relative;

          &:hover {
            background: transparent;
            border-color: transparent;
            color: $primary;

            &:before {
              box-shadow: 4px 0px 24.65px 4.35px rgba(0, 147, 231, 0.8);
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              background: transparent;
              border-left: 3px solid $primary;
            }
          }
        }
      }

      &.p-highlight {
        .p-panelmenu-header-link {
          position: relative;
          color: $primary;

          &:before {
            box-shadow: 4px 0px 24.65px 4.35px rgba(0, 147, 231, 0.8);
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            background: transparent;
            border-left: 3px solid $primary;
          }
        }
      }

      a {
        border: 0;
        background: initial;
        padding: .75rem 1.25rem;
        text-transform: uppercase;
        font-size: .875rem;

        .p-panelmenu-icon {
          position: absolute;
          right: .125rem;

          &.pi-chevron-down:before {
            content: "\f107";
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
          }

          &.pi-chevron-right:before {
            content: "\f105";
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
          }
        }

        .p-menuitem-icon {
          min-width: 1.5rem;
          font-size: 16px;
          text-align: center;
          margin-right: 0.5rem;
        }
      }

      a {
        &:focus {
          box-shadow: none;
        }
      }
    }

    .p-submenu-list {
      padding-left: 2.5rem;

      li {
        .p-menuitem-link {
          font-size: 12px;
          font-weight: 500;
          padding: .5rem 1rem;
          line-height: initial;
          //  @include mq(xxl) {
          //   font-size: 11px;
          //   }
        }
      }
    }

    .p-panelmenu-panel {

      //  margin-bottom: .625rem;
      &:first-child {
        .p-panelmenu-header {
          a {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
          }
        }
      }

      &:last-child {
        .p-panelmenu-header {
          &:not(.p-highlight) {
            a {
              border-top-right-radius: 0;
              border-top-left-radius: 0;
            }
          }
        }
      }
    }
  }
}

.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header>a {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.actvieMenu {
  color: #0093e7 !important;
  // font-size: 12px;
  // font-weight: 500;
  // padding: .5rem 1rem;
  // line-height: initial;
  // position: relative;
  // border-radius: 0;
  // transition: box-shadow 0.2s;
  // -webkit-user-select: none;
  // user-select: none;
  // display: flex;
  // align-items: center;
  // -webkit-user-select: none;
  // user-select: none;
  // cursor: pointer;
  // text-decoration: none;
  &:hover{ color: #0093e7  !important; }
}

.active{
  color: #0093e7 !important;
  &:hover{ color: #0093e7  !important; }
}