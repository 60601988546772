.lookup-table{
 .p-row-editor-init{
  font-size: 13px;
  .pi-pencil:before {
    content: "\f303";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
  }
 }
 .p-row-editor-save{
  font-size: 13px;
  .pi-check:before {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: $mdgsuccess;
  }
 }
 .p-row-editor-cancel{
  font-size: 13px;
  .pi-times:before {
    content: "\f00d";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: $mdgdanger;
  }
 }
}

.lookup-icon{
  font-size: 26px;
}