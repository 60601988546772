.side-nav {
  height: calc(100vh - 50px);
  position: fixed;
  top: 53px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.15);
  z-index: 1040;
  direction: ltr;
  background-color: $white;
  width: 230px;
  padding-top: .3125rem;

  //  transition: all .3s ease;
  @include mq(tablet) {
    position: absolute;
    left: 0;
    z-index: 1030;
  }

  &.collapsed {
    width: 88px;

    .switchplatform-icon {
      display: block;
    }

    .app-name {
      .switch-platform-drpdn {
        display: none;
      }

      .appname-main {
        margin-bottom: 0 !important;
      }
    }

    .nav-left {
      .header-left-title {
        &.header-menuicon {
          .fa-chevron-left:before {
            content: "\f054";
          }
        }
      }
    }

    @include mq(tablet) {
      left: -230px;
      width: 230px;
    }

    .p-panelmenu {
      .p-panelmenu-panel {
        position: relative;
      }

      .p-toggleable-content {
        position: absolute;
        z-index: 3;
        left: 100%;
        top: 0px;
        box-shadow: none;
        min-width: 220px;
        border: 1px solid #dddddd;

        @include mq(tablet) {
          position: initial;
          min-width: initial;
        }

        .p-submenu-list {
          padding-left: 0;
          max-height: 400px;
          overflow: auto;
        }

        .p-menuitem-text {
          display: block;
        }
      }
    }

    .p-panelmenu {
      .p-panelmenu-header-link {
        &:hover {
          .p-toggleable-content {
            display: block;
          }
        }
      }

      .p-menuitem-text {
        // font-size: 11px;
        font-weight: 500;
        display: none;

        @include mq(tablet) {
          display: block;
          // margin-left: .625rem;
        }
      }

      .p-panelmenu-header {
        a {
          font-weight: 400;

          .p-panelmenu-icon {
            font-size: 11px;

            @include mq(tablet) {
              font-size: 11px;
            }
          }

          .p-menuitem-icon {
            display: inline-block;
            margin: 0 auto;
            font-size: 25px;

            @include mq(tablet) {
              margin: 0;
              width: 1.5rem;
              font-size: 14px;
            }
          }
        }
      }
    }

    .appname-detail {
      display: none;

      @include mq(tablet) {
        display: block;
      }
    }

    .appname-main {
      justify-content: center;

      @include mq(tablet) {
        justify-content: start;
      }
    }
  }
}

.dashboard-menu {
  .p-toggleable-content {
    display: none;
  }
}

.app-name {
  // padding: 0.75rem 1.25rem;
  // border-bottom: 1px solid #dee2e6;
  // margin-bottom: 10px;
  position: relative;

  i {
    font-size: 24px;
    color: #ffffff;
  }

  h1 {
    font-size: 24px;
    margin: 0;
    color: #ffffff;
    line-height: inherit;
  }

  span {
    font-size: 13px;
  }
}

.switchplatform-icon {
  display: none;
}

.mdg-panelmenu{max-height:calc(100vh - 128px);overflow-y:auto}
.side-nav.collapsed .mdg-panelmenu{max-height:auto;overflow-y:initial}




// .single-menuitem{
//   display: flex;
//   align-items: center;
//   padding: 0.75rem 1.25rem;
//   .p-menuitem-icon{
//     min-width: 1.5rem;
//     font-size: 16px;
//     text-align: center;
//     margin-right: 0.5rem;
//   }
//   .p-menuitem-link{
//     padding: 0 !important;
//     font-weight: 600;
//   }
// }