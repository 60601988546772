ul{
 margin-bottom: 0;
 li{
  list-style: none;
 }
}

a {
 cursor: pointer;
 text-decoration: initial;
 color: $body-color;
}

.p-component{
 font-family: $font-family-sans-serif;
}

.invalid-feedback{
    font-size: 11px;
    position: absolute;
    bottom: -19px;
}

label{
    font-size: .875rem;
}

p{
    font-size: .875rem;
}

.col-form-label{
    font-size: .875rem;
}

label, span, p {
    color: inherit !important;
}

.border-bottom-dotted{
    border-bottom:1px dotted $gray-light6;
}
