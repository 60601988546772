.filter-part{
 padding-left: 1rem;
 .filter-part-title{
  text-transform: uppercase;
  font-size: .875rem;
  color: $mdg-title;
  background: $mdgsecondary;
  padding: .5rem .625rem;
  display: inline-block;
  border:0;
 }
}

.icon-group{
 position: absolute;
 right: 1.25rem;
}

.field-data-box{
 background: $white;
 border:1px solid $mdgprimary;
 position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.field-search{
 padding: .5rem;
 border-bottom: 2px solid $mdgprimary;
 .p-inputtext{
  padding-right: 0;
  width: 85%;
 }
}

.field-alphabet-letter{
 text-align: center;
 a{
  display: block;
  font-size: .75rem;
  text-transform: uppercase;
  margin-bottom: .1875rem;
 }
}

.field-list{
 padding: 0 .5rem;
 border-right: 1px solid $mdgprimary;
 height:486px;
 overflow: auto;
 .p-accordion {
  .p-accordion-header {
   .p-accordion-header-link {
    border:1px solid $gray-light6;
    border-width:0 0 1px 0;
    padding: .375rem .875rem .375rem 0;
   }
   &:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link{
    border-color: $gray-light6;
   }
  }
  .p-accordion-header:not(.p-disabled).p-highlight {
   .p-accordion-header-link{
    background: transparent;
    border-color: $gray-light6;
   }
  }
 }
 .p-accordion-header-text{
  font-size: .9375rem;
  font-weight: 400;
 }
 .p-accordion-header{
  a{
   &:hover{
    .list-hover-icon{
     display: inline-block;
     right: 14px;
     top: .375rem;
    }
   }
  }
 }
}

.field-listing{
 padding: 0 .5rem;
 border-bottom: 1px solid $gray-light6;
 .p-accordion-header-text{
   display: block;
   width: 100%;
   span{
     &.list-ellipsis{
      max-width: 70%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      vertical-align: middle;
     }
   }
 }
}

.field-sublist{
 padding-left: 0;
 li{
  font-size: .9375rem;
  border-bottom: 1px solid $gray-light6;
  padding: .25rem 0;
  position: relative;
  line-height: initial;
  cursor: pointer;
  padding-left: .625rem;
  padding-right: .625rem;
  &:hover{
   .list-hover-icon{
    display: block;
   }
   .dropdown-menu{
     li{
       padding: 0;
       .dropdown-item{
         font-size: .875rem;
       }
     }
   }
  }
  span{
    &:first-child{
     max-width: 75%;
     overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap;
     display: inline-block;
    }
  }
 }
}

.field-icons{
 padding: .625rem .5rem;
}

.add-edit-list{
 padding: .625rem .5rem;
 font-size: .875rem;
 p{
  margin: 0;
  border-bottom: 1px solid $gray-light6;
  padding-bottom:.1875rem;
 }
 a{
  padding-top:.1875rem;
  display: block;
 }
}

.list-hover-icon{
 display: none;
 position: absolute;
 right: 0;
 top: .3125rem;
 transition: $transition;
 a{
  margin-right: .625rem;
 }
}

.tooltip-filter-title{
  font-size: .875rem;
}

// dialog css START HERE
.load-template-dialog{
  .p-dialog-content{
    padding: .625rem 1.5rem;
  }
}

.icon-dropdown{
  .dropdown-toggle::after{
    display: none;
  }
}


.filter-grid-value{
  background-color: #4a5265;
  border-radius: 30px;
  display: inline-block;
  height: 8px;
  position: relative;
  top: 0;
  width: 8px;
}

.action-dots{
  background: transparent;
  border: 0;
  &.dropdown-toggle::after{
    display: none;
  }
  .fa-exchange-alt{
    transform: rotate(90deg);
  }
}

// .editable-cells-table{
//   &.p-datatable {
//     .p-datatable-thead {
//       tr{
//         th{
//           border-width: 0 1px 1px 0;
//           &:last-child{
//             border-right: 0;
//           }
//         }
//       }
//     }
//     .p-datatable-tbody{
//       tr{
//         td{
//           border-width: 0 1px 1px 0;
//           &:last-child{
//             border-right: 0;
//           }
//         }
//       }
//     }
//   }
// }

.kill-query-icon{
  color: #d9534f;
}

.licensable-icon{
  color:#00970C;
}

.locked-by-user{
  i{
    font-size: 14px;
  }
}

.custm-picklist-box {
  ::selection {
    background-color: transparent;
  }
}