.error-text-box {
	font-size: 12rem;
	min-height: 200px;
}

.error-text-box .text {
	fill: none;
	stroke-width: 6;
	stroke-linejoin: round;
	stroke-dasharray: 30 100;
	stroke-dashoffset: 0;
	animation: stroke 9s infinite linear
}

.error-text-box .text:nth-child(5n+1) {
	stroke: #f1556c;
	animation-delay: -1.2s
}

.error-text-box .text:nth-child(5n+2) {
	stroke: #f7b84b;
	animation-delay: -2.4s
}

.error-text-box .text:nth-child(5n+3) {
	stroke: #6658dd;
	animation-delay: -3.6s
}

.error-text-box .text:nth-child(5n+4) {
	stroke: #4fc6e1;
	animation-delay: -4.8s
}

.error-text-box .text:nth-child(5n+5) {
	stroke: #1abc9c;
	animation-delay: -6s
}

@keyframes stroke {
	100% {
		stroke-dashoffset: -400
	}
}

@media screen and (-ms-high-contrast:active),
(-ms-high-contrast:none) {
	.error-text-box .text {
		fill: #f1556c;
		stroke: #f1556c;
		stroke-width: 6;
		stroke-dasharray: 0 0;
		stroke-dashoffset: 0;
		animation: none
	}
}

.error-main{
 max-width: 480px;
 margin: 0 auto;
 width: 100%;
 text-align: center;
 transform: translate(-50%, -50%);
 position: absolute;
 top: 40%;
 left: 50%;
 right: 50%;
 h1{
  font-size: 1.5rem;
 }
 h3{
  font-size: 1rem;
 }
}

.access-denied-img{
	@include mq(md) {
		width: 100%;
	}
}
.download-file-icon {
	i {
		color: #96c93d;
		font-size: 5rem;
		border-radius: 100%;
		width: 150px;
		height: 150px;
		line-height: 130px;
	}

	&.download-file-error {
		i {
			color: red !important;
		}
	}
}