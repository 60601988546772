.p-tooltip  {
 &.mdg-tooltip{
  max-width: max-content;
 }
 &.p-tooltip-bottom {
  .p-tooltip-arrow{
   border-bottom-color: $mdgprimary;
  }
 }
 .p-tooltip-text{
  background-color: $white;
  color: $body-color;
  // min-width: 28.125rem;
 }
}