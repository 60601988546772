// This file contains  sass mixins.

// centre a block element
@mixin block-center {
    margin: {
        left: auto;
        right: auto;
    }
}

// pseudo selector
@mixin pseudo($display: block, $pos: absolute, $content: "") {
    content: $content;
    display: $display;
    position: $pos;
}

// placeholder
@mixin input-placeholder {
    &.placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }

    &::-webkit-input-placeholder {
        @content;
    }
}

// media query
$breakpoints: ("xxs": 375px,
    "xs": 480px,
    // < xs
    "sm": 576px,
    // >= sm
    "md": 768px,
    // >= md
    "lg": 992px,
    // >= lg
    "tablet": 1024px,
    "xl": 1200px,
    // >= xl
    "xxl": 1440px,
    "hd": 1600px,
    "fhd": 1920px,
);

@mixin mq($width, $type: max) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);

        @if $type==max {
            $width: $width - 1px;
        }

        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}

// truncate
@mixin truncate($truncation-boundary) {
    max-width: $truncation-boundary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// selection
@mixin selection {
    ::-moz-selection {
        @content;
    }

    ::selection {
        @content;
    }
}

// transition

@mixin transition($transition...) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}

