.manage-splash-dialog{
 p{
  margin-bottom: 0;
 }
 p{
  img{
    max-width: 100%;
    height: auto;
  }
 }
 &.p-dialog {
  .p-dialog-content{
   padding: 15px;
  }
 }
}