.upload-data-page{
    .p-radiobutton{
        height: auto;
    }
}

.file-drag-drop{
    width: 100%;
}

.upload-data-file-main{
    .p-fileupload-content{
        padding-left: 0;
    }
}

.data-search-seperate-field{
    background: #f9f9f9;
    padding: 1rem;
}

.copy-icon{
    position: absolute;
    right: -4px;
    top: -4px;
}
.copy-icon .p-button-icon{
    color: #1d1919 !important;
}
.copy-dialog{
    p{
        word-break: break-word;
    }
}